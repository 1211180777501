<template>
    <div class="row">
        <div class="col-12 my-5 py-5 order" v-if="pagination.total == 0 && loading">
            Carregando...
        </div>
        <div class="col-12 my-5 py-5 order" v-if="pagination.total == 0 && !loading">
            Não foi localizado nenhum imóvel com os filtros.
        </div>
        <div class="col-12 order" v-if="pagination.total != 0">
            <span><b>{{ pagination.total }}</b> IMÓVEIS</span>

            <a href="javascript:void(0)" :class="{ ativo: filter.sort === Sort.RECENTES }" class="bt-silver"
                @click="() => setSort(Sort.RECENTES)">MAIS RECENTES</a>
            <a href="javascript:void(0)" :class="{ ativo: filter.sort === Sort.MENOR_VALOR }" class="bt-silver"
                @click="() => setSort(Sort.MENOR_VALOR)">MENOR VALOR</a>
            <a href="javascript:void(0)" :class="{ ativo: filter.sort === Sort.MAIOR_VALOR }" class="bt-silver"
                @click="() => setSort(Sort.MAIOR_VALOR)">MAIOR VALOR</a>
        </div>

        <SearchResult :imovel="imovel" v-for="imovel in records" :key="imovel.id"></SearchResult>

    </div>
    <div class="row d-none d-md-flex ">
        <SearchPagination @update="updatePagination" :max_pages="3" :pagination="pagination"></SearchPagination>
    </div>
    <div class="row d-md-none">
        <SearchPagination @update="updatePagination" :max_pages="1" :pagination="pagination"></SearchPagination>
    </div>
</template>
<script lang="ts" setup>
import { Imovel, Sort, imovel_repository } from '@/store/imoveis';
import { Pagination } from '@/store/pagination';
import { ref, watch } from 'vue';
import SearchResult from './search-result.vue';
import SearchPagination from './search-pagination.vue';
import { search_url as url, filter } from '@/store/search';

interface Props {
    records: Imovel[]
}

const props = defineProps<Props>()
console.log({ records: props.records })
const records = ref<Imovel[]>(props.records || [])
const pagination = ref<Pagination>(new Pagination)
const loading = ref<boolean>(false)


pagination.value.page = Number(url.value.searchParams.get('page') || '1')
pagination.value.rows = Number(url.value.searchParams.get('rows') || pagination.value.rows)

const sort = url.value.searchParams.get('sort') as Sort || ''
if (sort) {
    filter.value.sort = sort
}


const load = async () => {
    try {
        loading.value = true
        records.value = await imovel_repository.search(filter.value, pagination.value)

        if (records.value.length === 1 && filter.value.codigo && filter.value.codigo != '') {
            let [imovel] = records.value
            location.href = `imovel/${imovel.codigo}/${imovel.slug}`
        }
    } catch (error) {
        console.error(error)
    }
    loading.value = false
}

const loadCount = async () => {
    try {
        pagination.value.total = await imovel_repository.count(filter.value)
    } catch (error) {
        console.error(error)
    }
}

const updatePagination = (new_pagination: Pagination) => {
    pagination.value = new_pagination
    load()

    const url = new URL(location.href)
    url.searchParams.set('page', pagination.value.page + '')
    url.searchParams.set('rows', pagination.value.rows + '')

    history.pushState({}, '', url)
}

const setSort = (sort: Sort) => {
    filter.value.sort = sort
    pagination.value.page = 1;
    load()

    const url = new URL(location.href)
    url.searchParams.set('sort', filter.value.sort + '')

    history.pushState({}, '', url)
}

load()
loadCount()


watch(() => filter.value, () => {
    pagination.value.page = 1

    load()
    loadCount()
})

</script>